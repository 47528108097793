import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import NavBar from "./components/NavBar";
import HomePage from "./pages/HomePage";
import PhotographyPage from "./pages/PhotographyPage.js";
import AboutPage from "./pages/AboutPage.js";
import ProjectsPage from "./pages/ProjectsPage.js";
import ContactPage from "./pages/ContactPage.js";

import "./App.css";

function App() {

  return (
    <div className="App">
      <Router >
        <NavBar />
        <div className="container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/photography" element={<PhotographyPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
