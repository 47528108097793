import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useMediaQuery, useTheme } from '@mui/material';
import '../assets/styles/PictureGrid.css';

export default function PictureGrid(props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const getCols = () => {

    if (isSmallScreen) {
      return 1;
    }
    return 3;
  };

  const cols = parseInt(getCols(), 10);

  return (
    <div className="PictureGrid">
      <ImageList className={"ImageList"} variant="masonry" cols={cols} gap={24}>
        {props.images.map((item) => (

          <ImageListItem className="Image" key={item.url}>
            <img
              src={item.url}
              alt={item.alt}
              loading="lazy"
            />

            {/* <ImageListItemBar
              typographyProps={{
                style: {
                  fontFamily: "Nunito",
                }
              }}
              title={item.alt}
              position="top"
            /> */}
          </ImageListItem>

        ))}
      </ImageList>
    </div>
  );
}