export const intro = [
    "Hi! I'm Aaron Parihar. Welcome to my website. I'm coding this from scratch, so please be patient with it! I'm a computer engineering student with a deep-rooted love for technology. Growing up in the Bay Area, I've been surrounded by innovation, which has fueled my curiosity and passion for exploring the possibilities that technology brings.",
    "",
    "In addition to my studies, I find joy in various interests. I'm a pilot, always waiting for the next opportunity to be in the sky. Photography allows me to capture and share the beauty of aviation, while chess keeps my mind sharp. I also believe in the importance of health, which is why I dedicate time to working out.",
    "",
    "My passion for knowledge drives me to constantly expand my understanding of technology. I'm always eager to learn new things and stay up-to-date with the latest advancements in the field. I enjoy working on projects that challenge me to think outside the box, push my limits, and pick up new skills.",
    "",
];
export const aerial = [
    {
        url: "https://i.imgur.com/6p47boS.jpg",
        alt: "Sunset over Purdue University Airport (KLAF)",
    },
    {
        url: "https://i.imgur.com/qW1ORBU.jpg",
        alt: "Sunset over the Bay Area",
    },
    {
        url: "https://i.imgur.com/9vfCpm4.jpg",
        alt: "Storm Clouds",
    },
    {
        url: "https://i.imgur.com/2d91riE.jpg",
        alt: "Storm Clouds",
    },

    {
        url: "https://i.imgur.com/mJdiBw7.jpg",
        alt: "Snowy Cornfields",
    },
    {
        url: "https://i.imgur.com/gxOlmXG.jpg",
        alt: "San Fransisco, Oakland, and Alameda",
    },
    {
        url: "https://i.imgur.com/0bGmBOJ.jpg",
        alt: "San Fransisco Downtown",
    },
    {
        url: "https://i.imgur.com/TZdytok.jpg",
        alt: "Sunset",
    },
    {
        url: "https://i.imgur.com/7fRWf0E.jpg",
        alt: "Golden Gate Bridge at Night",
    },
    {
        url: "https://i.imgur.com/wOgsaR6.jpg",
        alt: "San Fransisco Downtown and Bay Bridge at Night",
    },
    {
        url: "https://i.imgur.com/D72hKd8.jpg",
        alt: "Formation Flying",
    },
    {
        url: "https://i.imgur.com/Bcg2iSz.jpg",
        alt: "University of Illinois at Urbana-Champaign at Night",
    },
    {
        url: "https://i.imgur.com/JZG6dBm.jpg",
        alt: "Port of Oakland at Sunset",
    },
    {
        url: "https://i.imgur.com/5CSQYny.jpg",
        alt: "Livermore Municipal Airport (KLVK) Runway 7L Approach",
    },
];

export const land = [
    {
        url: "https://i.imgur.com/tPU0HXy.jpg",
        alt: "Seattle Skyline",
    },
    {
        url: "https://i.imgur.com/lSL4GA4.jpg",
        alt: "Village West Apartments Purdue",
    },
    {
        url: "https://i.imgur.com/s1VeO4G.jpg",
        alt: "Purdue Pilots, Inc. Airplanes",
    },
    {
        url: "https://i.imgur.com/l7qPGnp.jpg",
        alt: "Sunset at Schaefer Ranch",
    },
    {
        url: "https://i.imgur.com/mUa2DE5.jpg",
        alt: "Bay Bridge from San Fransisco Pier",
    },
    {
        url: "https://i.imgur.com/XM6qNqs.jpg",
        alt: "Sunset at Purdue University",
    },
    {
        url: "https://i.imgur.com/EHzowGq.jpg",
        alt: "Mooney M20J N5772R Instrument Panel",
    },
    {
        url: "https://i.imgur.com/U7Q9Dmx.jpg",
        alt: "Night at Purdue University Airport (KLAF)",
    },
    {
        url: "https://i.imgur.com/iAmbWhJ.jpg",
        alt: "N3033T at Purdue University Airport (KLAF)",
    },
    {
        url: "https://i.imgur.com/njWn1ea.jpg",
        alt: "Purdue Pilots, Inc. Airplanes at Sunset",
    },
    {
        url: "https://i.imgur.com/GIR4ENs.jpg",
        alt: "Sunset at Purdue University",
    },
    {
        url: "https://i.imgur.com/dNnnuCy.jpg",
        alt: "Purdue University Airport (KLAF) Ramp at Sunset",
    },
    {
        url: "https://i.imgur.com/txX9mfF.jpg",
        alt: "I-680 Sunset",
    },
];

export const projects = [
    {
        index: 0,
        title: "aaronparihar.com",
        description: "I created this website using ReactJS, MUI, HTML, and CSS. This project is a work in progress, but I am excited to share my progress with you. The website is designed to be a showcase of my skills and abilities.",
        keywords: "ReactJS, MUI, HTML, CSS",
        hardware: false,
        embedded: false,
        software: true,
    },
    {
        index: 1,
        title: "Spotify Dashboard (WIP)",
        description: "I am currently working on a Spotify dashboard in Azure using ReactJS, MUI, Motion, and Azure. This project is a work in progress, but I am excited to share my progress with you. The dashboard will allow users to view their Spotify friends' listening activity, generate playlists based on their listening habits, and recommend songs and artists. I am using the Spotify API to access my listening activity and the Azure Machine Learning service to build an AI model that can generate playlists and recommend songs and artists.",
        keywords: "ReactJS, MUI, HTML, CSS, Spotify API, SQL, Azure, Full Stack",
        hardware: false,
        embedded: false,
        software: true,
    },
    {
        index: 2,
        title: "Smart Chess Board (WIP)",
        description: "I am currently working on a smart chess board that uses hall effect sensors, daisy-chained shift registers, and a microcontroller connected to a computer via USB. The computer will run an application that I designed to provide real-time analysis and post-game analysis using the Stockfish API.",
        keywords: "Circuit Design, PCB Design, KiCad, C, Microcontrollers, USB, Drivers",
        hardware: false,
        embedded: true,
        software: false,
    },
    {
        index: 3,
        title: "FPGA Alarm Clock",
        description: "I built an FPGA alarm clock using a DE0 development board. The clock used frequency division, flip flops, logic gates, concurrent logic, PWM, sequential logic, and concurrent logic. The alarm clock used concurrent logic to run the different parts of the clock simultaneously. All of these tasks needed to be performed at the same time. The alarm clock also used sequential logic to store the alarm time and to trigger the alarm when the alarm time was reached.",
        keywords: "Digital Logic, Hardware Description Language, JTAG",
        hardware: false,
        embedded: true,
        software: false,
    },
    {
        index: 4,
        title: "Autonomous RC Car",
        description: "I coded an autonomous RC car using I2C, PWM, timers, interrupts, lidar sensors, infrared sensors, and multiplexers. The car had features such as front and back braking, cruise control, and anti-rollover. It was able to navigate around obstacles autonomously and maintain a constant speed.",
        keywords: "I2C, PWM, C, Microcontrollers, ADC, Timers, Interrupts, Testing",
        hardware: false,
        embedded: true,
        software: false,
    },
    {
        index: 5,
        title: "Robotic Arm",
        description: "I coded a simple robotic arm that used a variety of technologies, including microcontrollers, timers, EEPROM, potentiometers, push buttons, DC motors, and a serial interface. The arm had three degrees of freedom, which means that it could move in three different directions: up and down, left and right, and rotating around its base. The arm was controlled using potentiometers and push buttons. The potentiometers were used to control the position of the motors, and the push buttons were used to move the DC motors. The serial interface was used to choose between different routes.",
        keywords: "Microcontrollers, Timers, EEPROM, UART, PWM, ADC, Interrupts, Servos, Stepper Motors, DC Motors, C, Project Management, Testing",
        hardware: false,
        embedded: true,
        software: false,
    },
    {
        index: 6,
        title: "ATMega328 Microcontroller Board",
        description: "I soldered an ATmega328 board from scratch and programmed it using ISP. The board has an ATmega328 microcontroller, a crystal oscillator, a few capacitors, and a few resistors. I soldered the components onto a piece of perfboard and then programmed the microcontroller using an ISP programmer.",
        keywords: "Circuit Design, ISP, Microcontrollers, Testing",
        hardware: true,
        embedded: true,
        software: false,
    },
    {
        index: 7,
        title: "Superheterodyne FM Receiver",
        description: "I soldered a superheterodyne FM receiver from scratch and tested it while learning about RF communications. The receiver has a filter, RF amplifier, a mixer, a local oscillator, an IF amplifier, a demodulator, and an audio amplifier. I soldered the components onto perfboard and then tested the receiver using an oscilloscope, spectrum analyzer, and RF generator. I was able to pick up radio stations.",
        keywords: "RF, FM, Circuit Design, Testing",
        hardware: true,
        embedded: false,
        software: false,
    },
    {
        index: 8,
        title: "Escape Room Scheduler",
        description: "The scheduler is a desktop application that is accessed on the user's computer. The application uses Java for the back-end and JavaFX for the front-end. The scheduler uses Network I/O to communicate with other users. The scheduler uses Concurrency to handle multiple users simultaneously. The scheduler uses File I/O to store user data locally.",
        keywords: "Java, GUI, Network I/O, Concurrency, File I/O, Project Management",
        hardware: false,
        embedded: false,
        software: true,
    },
    {
        index: 9,
        title: "Solar Powered Backpack",
        description: "I made a solar powered backpack with solar panels, buck regulator, and battery pack. The backpack has two solar panels, a buck regulator, and a battery pack. I learned about the different phases of the design process, from ideation to prototyping to testing.",
        keywords: "Circuit Design",
        hardware: true,
        embedded: false,
        software: false,
    }
];
export const experience = [
    {
        index: 0,
        title: "IT Intern",
        company: "Reata Pharmaceuticals",
        location: "Plano, TX",
        date: "May 2023 - August 2023",
        description: "As an intern on the IT Infrastructure team at Reata Pharmaceuticals, I gained valuable experience in networking and security. I worked on projects involving Netskope, Nile, and learned about networking, with topics including TCP/IP, DNS, DHCP, ARP, NAT, VLANs, and subnets. I also worked with AD, AAD, Microsoft Defender, and Intune. I also had the opportunity to design the IT infrastructure for a hypothetical company. I had to consider a variety of factors, such as the company's size and industry. I learned the many considerations when designing an infrastructure for a heavily regulated industry. I also witnessed the acquisition of Reata Pharmaceuticals by Biogen, which was a valuable experience.",
    },
    {
        index: 1,
        title: "Undergraduate Research Assistant",
        company: "Digital/Biomedical Embedded Systems & Technology Laboratory",
        location: "West Lafayette, IN",
        date: "May 2022 - Present",
        description: "As an undergraduate research assistant in the DBEST lab, I was responsible for creating a data collection dashboard for a machine learning project for early detection of diabetes. The dashboard was created using Mui, ReactJS, and Supabase. It was a valuable tool for the project, allowing the team to track the progress of the project, identify trends, and make informed decisions. ",
    },
    {
        index: 2,
        title: "Vice President",
        company: "Purdue Pilots, Inc.",
        location: "West Lafayette, IN",
        date: "December 2021 - Present",
        description: "I served as the Vice President of Purdue Pilots Inc., a student-run aviation organization at Purdue University. I managed a $200k revenue, 80 members, and $200k of assets. I developed and implemented the organization's strategic plan, managed the organization's finances, and oversaw the maintenance and operation of the organization's airplanes. ",
    },
    {
        index: 3,
        title: "ECET 229 Teaching Assistant",
        company: "Purdue University",
        location: "West Lafayette, IN",
        date: "August 2022 - December 2022",
        description: "I taught students fundamentals of digital logic, including logic gates, flip-flops, latches, state machines, frequency division, PWM, combinational logic, sequential logic, and more. I taught students how to use these concepts to design and build digital circuits.",
    },
    {
        index: 4,
        title: "Eagle Scout",
        company: "Boy Scouts of America",
        location: "Pleasanton, CA",
        date: "August 2013 - June 2021",
        description: "I worked with my troop to plan, raise funds, and build a large shelving unit for my school's band. This project required me to develop leadership, planning and organization, problem-solving, and communication skills. I also ran the 200+ member troop, coordinating meetings, events, and activities along the life skills learned throughout my journey in the Boy Scouts.",
    },
    {
        index: 5,
        title: "Server",
        company: "Bentolicious",
        location: "Pleasanton, CA",
        date: "May 2022 - August 2022",
        description: "I served as a server at Bentolicious, where I was responsible for taking orders, delivering food, and providing excellent customer service. I enjoyed working with the team and helping customers find the perfect bento box for their taste.",
    },
    {
        index: 6,
        title: "Tutor",
        company: "Kumon",
        location: "Pleasanton, CA",
        date: "November 2019 - June 2021",
        description: "I was a tutor at Kumon, where I provided personalized instruction in math and English to students of all ages. I also fostered critical thinking skills and created individual lesson plans. I was always looking for ways to improve my teaching methods and help my students succeed.",
    },
];
export const skills = [
    {
        index: 0,
        title: "C",
        rating: 3,
    },
    {
        index: 1,
        title: "Java",
        rating: 3,
    },
    {
        index: 2,
        title: "ReactJS",
        rating: 3,
    },
    {
        index: 3,
        title: "HTML",
        rating: 3,
    },
    {
        index: 4,
        title: "CSS",
        rating: 2,
    },
    {
        index: 5,
        title: "SQL",
        rating: 3,
    },
    {
        index: 6,
        title: "Altera HDL",
        rating: 4,
    },
    {
        index: 7,
        title: "JSON",
        rating: 3,
    },
    {
        index: 8,
        title: "O365 Suite",
        rating: 4,
    },
    {
        index: 9,
        title: "Eagle PCB",
        rating: 5,
    },
    {
        index: 10,
        title: "KiCAD",
        rating: 3,
    },
    {
        index: 11,
        title: "Atmel Studio",
        rating: 5,
    },
    {
        index: 12,
        title: "Arduino IDE",
        rating: 4,
    },
    {
        index: 13,
        title: "Bench Equipment",
        rating: 4,
    },
    {
        index: 14,
        title: "GitHub",
        rating: 3,
    },
    {
        index: 15,
        title: "Jira",
        rating: 3,
    },
    {
        index: 16,
        title: "FPGAs",
        rating: 3,
    },
    {
        index: 17,
        title: "Microcontrollers",
        rating: 4,
    },
    {
        index: 18,
        title: "I2C, SPI, USART",
        rating: 3,
    },
];
export const languages = [
    {
        index: 0,
        title: "English",
        rating: 5,
    },
    {
        index: 1,
        title: "Hindi",
        rating: 4,
    },
    {
        index: 2,
        title: "Spanish",
        rating: 4,
    },
];