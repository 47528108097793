import "../assets/styles/ContactPage.css";
import { FaEnvelope, FaLinkedin } from "react-icons/fa";

export default function ContactPage() {

    return (
        <div className="ContactPage">
            <div className="ContactWrapper">
                <p className="Title">Contact Me</p>
            </div>
            <div className="Contact">
                <a href="mailto:apariha@purdue.edu" target="_blank" rel="noreferrer" className="IconWrapper">
                    <p>Email</p>
                    <FaEnvelope className="Icon" />
                </a>
                <a href="https://www.linkedin.com/in/aaron-parihar/" target="_blank" rel="noreferrer" className="IconWrapper">
                    <p>LinkedIn</p>
                    <FaLinkedin className="Icon" />
                </a>
            </div>
        </div>
    )
}