import React from "react";
import "../assets/styles/PhotographyPage.css";
import Button from "../components/Button";
import PictureGrid from "../components/PictureGrid";
import { aerial, land } from "../assets/Data.js";

export default function PhotographyPage() {
  const [active, setActive] = React.useState("aerial");

  const handleClick = (value) => {
    setActive(value);
  };

  return (
    <div className="PhotographyPage">
        <p className="PhotographyIntro">I am fairly new to photgrahy; I started dabbling in it around the same time I got into aviation. I love being able to capture and share my experiences and memories in a creative way. I am planning to learn about DSLR cameras, and hope to expand my knowledge of photography.</p>
        <div className="PhotographyTabs">
          <Button className={"Tab" + ((active === "aerial") ? "Active" : "")} text="Aerial" onClick={() => handleClick("aerial")} />
          <Button className={"Tab" + ((active === "land") ? "Active" : "")} text="Land" onClick={() => handleClick("land")}/> 
        </div>
        <div className="PhotographyGrid">
          {(active === "aerial") ? <PictureGrid images={aerial} /> : <PictureGrid images={land} />}
        </div>
    </div >
  );
}