import React, { useEffect } from "react";
import "../assets/styles/Navbar.css";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from '@mui/material';
import Hamburger from 'hamburger-react'

export default function NavBar() {
  const navigate = useNavigate();
  const [active, setActive] = React.useState(window.location.pathname);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  function handleClick(route) {
    navigate(route);
    setOpen(false);
  }

  function handleOpen() {
    setOpen(!open);

  }

  // eslint-disable-next-line
  useEffect(() => {
    setActive(window.location.pathname);
  });

  return (
    <>
      {isMobile ? (
        <div className="Navbar">
          <div onClick={() => handleClick("/")} className="NavbarTitle">
          <img src={require("../assets/images/logo.png")} alt="Aaron Parihar" className="Logo" />
          </div>
          <Hamburger onToggle={handleOpen} toggled={open} color="#F3FEF0" className="Dropdown" />
          {open && (
            <div className="NavbarButtons">
              <Button
                onClick={() => handleClick("/")}
                text="Home"
                className={active === "/" ? "NavButtonActive" : "NavButton"}
              />
              <Button
                onClick={() => handleClick("/about")}
                text="About"
                className={active === "/about" ? "NavButtonActive" : "NavButton"}
              />
              <Button
                onClick={() => handleClick("/photography")}
                text="Photography"
                className={active === "/photography" ? "NavButtonActive" : "NavButton"}
              />
              <Button
                onClick={() => handleClick("/projects")}
                text="Projects"
                className={active === "/projects" ? "NavButtonActive" : "NavButton"}
              />
              <Button
                onClick={() => handleClick("/contact")}
                text="Contact"
                className={active === "/contact" ? "NavButtonActive" : "NavButton"}
              />
            </div>
          )}
        </div>) : (
        <div className="Navbar">
          <div onClick={() => handleClick("/")} className="NavbarTitle">
          <img src={require("../assets/images/logo.png")} alt="Aaron Parihar" className="Logo" />
          </div>
          <div className="NavbarButtons">
            <Button
              onClick={() => handleClick("/")}
              text="Home"
              className={active === "/" ? "NavButtonActive" : "NavButton"}
            />
            <Button
              onClick={() => handleClick("/about")}
              text="About"
              className={active === "/about" ? "NavButtonActive" : "NavButton"}
            />
            <Button
              onClick={() => handleClick("/photography")}
              text="Photography"
              className={active === "/photography" ? "NavButtonActive" : "NavButton"}
            />
            <Button
              onClick={() => handleClick("/projects")}
              text="Projects"
              className={active === "/projects" ? "NavButtonActive" : "NavButton"}
            />
            <Button
              onClick={() => handleClick("/contact")}
              text="Contact"
              className={active === "/contact" ? "NavButtonActive" : "NavButton"}
            />
          </div>
        </div>)}
    </>
  );
}
