import React, { useState, useEffect } from "react";
import ProjectCard from "../components/ProjectCard";
import "../assets/styles/ProjectsPage.css";
import { projects } from "../assets/Data.js";
import Button from "../components/Button";


export default function ProjectsPage() {
    const [allTech, setAllTech] = useState(true);
    const [hardware, setHardware] = useState(false);
    const [embedded, setEmbedded] = useState(false);
    const [software, setSoftware] = useState(false);

    const handleClick = (filter) => {
        if (filter === "technology") {
            if (!allTech) {
                setAllTech(true);
                setHardware(false);
                setEmbedded(false);
                setSoftware(false);
            }
        }
        else if (filter === "hardware") {
            setHardware(!hardware);
            setAllTech(false);
        }
        else if (filter === "embedded") {
            setEmbedded(!embedded);
            setAllTech(false);
        }
        else if (filter === "software") {
            setSoftware(!software);
            setAllTech(false);
        }
    }

    useEffect(() => {
        if (!hardware && !embedded && !software) {
            setAllTech(true);
        }
        if (hardware && embedded && software) {
            setAllTech(true);
            setSoftware(false);
            setEmbedded(false);
            setHardware(false);
        }
    }, [hardware, embedded, software]);

    return (
        <div className="ProjectsPage">
            <div className="ProjectsIntro">
                <p>I am a passionate learner and I am always looking for new ways to challenge myself. I am also a creative person and I enjoy working on projects that allow me to express myself. On this page, you will find a selection of my personal and academic projects. These projects represent my interests and passions, and they showcase my skills and abilities.</p>
            </div>
            <div className="ProjectsFilter">
                <div className="FilterRow">
                    <div className="FilterTitle">
                        Project Technology
                    </div>
                    <Button
                        className={allTech ? "FilterActive" : "Filter"}
                        text={"All"}
                        onClick={() => { handleClick("technology") }}
                    />
                    <Button
                        className={hardware ? "FilterActive" : "Filter"}
                        text={"Hardware"}
                        onClick={() => { handleClick("hardware") }}
                    />
                    <Button
                        className={embedded ? "FilterActive" : "Filter"}
                        text={"Embedded"}
                        onClick={() => { handleClick("embedded") }}
                    />
                    <Button
                        className={software ? "FilterActive" : "Filter"}
                        text={"Software"}
                        onClick={() => { handleClick("software") }}
                    />
                </div>
            </div>
            <div className="ProjectsGrid">
                {projects.map((project) => (
                    <ProjectCard
                        key={project.index}
                        id={project.index}
                        title={project.title}
                        description={project.description}
                        keywords={project.keywords}
                        all={allTech}
                        params={[project.hardware, project.embedded, project.software]}
                        filter={[hardware, embedded, software]}
                    />
                ))}
            </div>
        </div>
    );
}
