import React, { useRef } from "react";
import "../assets/styles/AboutPage.css";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa";
import { intro, experience, skills, languages } from "../assets/Data";
import Rating from '@mui/material/Rating';

export default function AboutPage() {
  const navigate = useNavigate();

  const toProjects = () => {
    navigate("/projects");
  };

  const educationRef = useRef(null);
  const skillsRef = useRef(null);
  const experienceRef = useRef(null);

  const scroll = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div className="AboutPage">
      <div className="Intro">
        <div className="IntroWrapper">
          <img className="IntroImage" src={require("../assets/images/aaron.jpg")} alt="Aaron Parihar" />
          <div className="IntroText">
            {intro.map((text, index) => (
              <p key={index}>{text} <br /> </p>
            ))}

            <div className="MenuBar">
              <Button
                className="About"
                text="Education"
                onClick={() => scroll(educationRef)}
              />
              <Button
                className="About"
                text="Skills"
                onClick={() => scroll(skillsRef)}
              />
              <Button
                className="About"
                text="Experience"
                onClick={() => scroll(experienceRef)}
              />
              <Button
                className="About"
                text="Projects"
                onClick={toProjects}
              />
            </div>
          </div>
        </div>
        <FaArrowDown className="AboutFooter" onClick={() => scroll(educationRef)} />
      </div>
      <div className="Education" ref={educationRef}>
        <p className="EducationTitle">Education</p>
        <div className="EducationWrapper">
          <p className="PurdueTitle">PURDUE UNIVERSITY</p>
          <img className="EducationImage" src={require("../assets/images/purdue.png")} alt="Purdue University" />
          <p className="PurdueDescription">B.S., Honors College</p>
          <p className="PurdueDescription">Major: Computer Engineering</p>
          <p className="PurdueDescription">Minors: Computer Science, Computer & Information Technology</p>
          <p className="PurdueDescription">Overall GPA: 3.98</p>
          <p className="PurdueDescription">Major GPA: 4.0</p>
          <p className="PurdueDescription">4x Dean's List & Semester Honors</p>
          <p className="PurdueDescription">Graduation May 2025</p>
        </div>
        <FaArrowDown className="EducationFooter" onClick={() => scroll(skillsRef)} />
      </div>
      <div className="Skills" ref={skillsRef}>
        <p className="SkillsTitle">Skills</p>
        <div className="SkillsWrapper">
          <ul className="SkillsColumn">
            {skills.map((skill) => (
              <div className="SkillsCard" key={skill.index}>
                <p className="SkillsCardTitle">{skill.title}</p>
                <Rating name="read-only" value={skill.rating} readOnly />
              </div>
            ))}
          </ul>
          <ul className="SkillsColumn">
            {languages.map((language) => (
              <div className="SkillsCard" key={language.index}>
                <p className="SkillsCardTitle">{language.title}</p>
                <Rating name="read-only" value={language.rating} readOnly />
              </div>
            ))}
          </ul>
        </div>
        <FaArrowDown className="SkillsFooter" onClick={() => scroll(experienceRef)} />
      </div>
      <div className="Experience" ref={experienceRef}>
        <p className="ExperienceTitle">Experience</p>
        <div className="ExperienceWrapper">
          {experience.map((experience, index) => (
            <div className="ExperienceBox" key={index}>
              <div className="ExperienceCard" key={experience.index}>
                <p className="ExperienceCardTitle">{experience.title}, {experience.company}</p>
                {/* <p className="ExperienceCardText">{experience.company}</p> */}
                <p className="ExperienceCardText">{experience.location}, {experience.date}</p>
                {/* <p className="ExperienceCardText">{experience.date}</p> */}
                <p className="ExperienceCardText">{experience.description}</p>
              </div>
              {experience.index !== 6 && <div className="Divider"/>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
