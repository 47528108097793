import React, { useState, useEffect } from "react";
import "../assets/styles/HomePage.css";

export default function HomePage() {
  const texts = ["Computer Engineering Student", "Pilot", "Photography Enthusiast", "Chess Aficionado"];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentIndex, texts.length]);

  return (
      <div className="HomePage">
        <div className="Home">
          <div className="Title">
            <h1>Aaron Parihar</h1>
          </div>
          <div className="fade-text">
            {texts[currentIndex]}
          </div>
        </div>
      </div>
    );
  }
