import * as React from "react";
import "../assets/styles/ProjectCard.css"

export default function ProjectCard(props) {
    if (
        props.all ||
        !((!props.filter[0] && props.params[0]) ||
            (!props.filter[1] && props.params[1]) ||
            (!props.filter[2] && props.params[2]))
    ) {
        return (
            <div div className="ProjectCard" >
                <div className="PCTitle">
                    <p>{props.title}</p>
                </div>
                <div className="PCDescription">
                    <p>{props.description}</p>
                </div>
                <div className="PCKeywords">
                    <p>Skills: {props.keywords}</p>
                </div>
            </div>
        )
    } else {
        return null;
    }
}